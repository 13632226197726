import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import { zeroAddress } from 'viem';
import { useAccount } from 'wagmi';
import { writeContract, readContract, waitForTransaction } from '@wagmi/core';
import { AiOutlineHome, AiOutlineRight } from "react-icons/ai";
import { contractAddress, csdogeTokenDecimals } from '../../../utils/config';
import loopartCsdogeAbi from '../../../abis/loopart-csdoge.abi.json';
import { delay, fetchNFTs } from '../../../utils';
import { INFTInfo, INFTcategory, IStakingPool } from '../../../interfaces';
import bscLogo from '../../../assets/images/chain-logos/bsc.svg';

enum FilterTypeEnum {
    MyCsdoge,
    MyStaking,
}

const StakingPoolDetail = () => {
    const { address } = useAccount();
    const [searchParams] = useSearchParams();
    const [filterType, setFilterType] = useState<FilterTypeEnum>(FilterTypeEnum.MyCsdoge);
    const [userNfts, setUserNfts] = useState<INFTInfo[]>([]);
    const [userStakeNfts, setUserStakeNfts] = useState<INFTInfo[]>([]);
    const [categoryName, setCategoryName] = useState<string>();
    const [stakingPool, setStakingPool] = useState<IStakingPool>();
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const [amounts, setAmounts] = useState<(number | undefined)[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStaking, setIsStaking] = useState<boolean>(false);
    const [isUnstaking, setIsUnstaking] = useState<boolean>(false);
    const [isClaiming, setIsClaiming] = useState<boolean>(false);

    const poolId = searchParams.get('poolId');

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            if (address && poolId) {
                const [nftsFetched, stakingPool, nftCategories, userStakeNfts] = await Promise.all([
                    fetchNFTs({ address }),
                    readContract({
                        address: contractAddress,
                        abi: loopartCsdogeAbi,
                        functionName: 'getStakingPool',
                        args: [Number(poolId)]
                    }),
                    readContract({
                        address: contractAddress,
                        abi: loopartCsdogeAbi,
                        functionName: 'getNFTCategories',
                    }),
                    readContract({
                        address: contractAddress,
                        abi: loopartCsdogeAbi,
                        functionName: 'getUserStakedNFTs',
                        args: [
                            address,
                            Number(poolId),
                            0,
                            100,
                            true
                        ]
                    })
                ]);

                const userNfts: INFTInfo[] = [];
                const userStakeNftsList: INFTInfo[] = [];

                console.log('nftsFetched', nftsFetched);

                await Promise.all(
                    nftsFetched
                        .filter(x => x.contractType == 'ERC1155'
                            && x.tokenAddress.equals(contractAddress)
                            && ((x.metadata as any).attributes as any[]).find(x => x.trait_type == 'category_id' && x.value == (stakingPool as IStakingPool).categoryId.toString())
                        )
                        .map(async (item) => {
                            try {
                                userNfts.push({
                                    tokenId: BigInt(item.tokenId),
                                    amount: BigInt(item?.amount || 0),
                                    boughtAmount: BigInt(0),
                                    price: BigInt(0),
                                    categoryId: BigInt(1),
                                    paymentTokenAddress: zeroAddress,
                                    isNativePayment: true,
                                    name: (item?.metadata as any).name || '',
                                    description: (item?.metadata as any).description || '',
                                    tokenUri: item?.tokenUri || '',
                                    imgUri: (item?.metadata as any)?.image || '',
                                    creatorName: ''
                                })
                            } catch { }
                        }));

                await Promise.all((userStakeNfts as any[]).filter(x => !x.isUnstaked).map(async (item) => {
                    try {
                        userStakeNftsList.push({
                            ...item.nftInfo,
                            stakedId: item?.id,
                            stakedAmount: item?.stakedAmount,
                            stakedAt: item?.stakedAt
                        });
                    } catch { }
                }));

                const categoryName = (nftCategories as INFTcategory[]).find(x => x.id.toString() == (stakingPool as IStakingPool).categoryId.toString())?.name;
                setCategoryName(categoryName);
                setStakingPool(stakingPool as any);
                setUserNfts(userNfts);
                setUserStakeNfts(userStakeNftsList);
            }
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    const handleStake = async (index: number, tokenId: number) => {
        const amount = amounts[index];
        if (!amount || amount < 0) {
            return toast.warn('Please input amount');
        }

        if (amount > userNfts[index].amount) {
            return toast.warn('Insufficient balance');
        }

        const [currentTime] = await Promise.all([
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'getCurrentTime',
            }),
        ]);

        if (currentTime as number > Number(stakingPool?.endTime.toString())) {
            return toast.warn('Not time to stake');
        }

        setIsStaking(true);
        setSelectedIndex(index);

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'stake',
                args: [
                    poolId,
                    tokenId,
                    amount
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            await delay(5000);
            await initialize();

            toast.success('Successful to stake tokens');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to stake tokens');
        }

        setIsStaking(false);
        setSelectedIndex(undefined);
    }

    const handleUnstake = async (index: number, tokenId: number) => {
        const [currentTime] = await Promise.all([
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'getCurrentTime',
            }),
        ]);

        if (currentTime as number < Number(stakingPool?.endTime.toString())) {
            return toast.warn('Not time to unstake');
        }

        setIsUnstaking(true);
        setSelectedIndex(index);

        const stakedId = userStakeNfts[index].stakedId;

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'unstake',
                args: [
                    poolId,
                    tokenId,
                    Number(stakedId?.toString())
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            await delay(5000);
            await initialize();

            toast.success('Successful to unstake');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to unstake');
        }

        setIsUnstaking(false);
        setSelectedIndex(undefined);
    }

    const handleClaim = async () => {
        const [currentTime, hasUserClaimed] = await Promise.all([
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'getCurrentTime',
            }),
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'userClaimedRewards',
                args: [
                    address,
                    poolId
                ]
            }),
        ]);

        if (currentTime as number < Number(stakingPool?.endTime.toString())) {
            return toast.warn('Not time to claim');
        }

        if (hasUserClaimed) {
            return toast.warn('Already claimed');
        }

        setIsClaiming(true);

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'claimRewards',
                args: [
                    Number(poolId),
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            await initialize();

            toast.success('Successful to claim rewards');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to claim rewards');
        }

        setIsClaiming(false);
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, [address, poolId]);

    return (
        <div className='flex flex-col items-center w-full p-[20px_20px_30px] md:p-[30px_30px_50px]'>
            <div className='w-full max-w-[1336px]'>
                {
                    !isLoading ? (
                        stakingPool ? (
                            <>
                                {/* Breadcrumb */}
                                <div className='flex items-center gap-1 text-[14px] mb-2'>
                                    <Link to={'/'}><AiOutlineHome className='text-[18px] hover:text-[#99B7FF] mb-[0.5px]' /></Link>
                                    <AiOutlineRight className='text-[#8E8E8E]' />
                                    <Link to={'/staking-pools'} className='hover:text-[#99B7FF]'>Staking Pools</Link>
                                    <AiOutlineRight className='text-[#8E8E8E]' />
                                    <span className='text-[#8E8E8E]'>{`${categoryName}'s Pool`}</span>
                                </div>

                                <div className='flex flex-col md:flex-row items-center gap-[20px]'>
                                    <div className='w-full bg-[linear-gradient(132deg,rgba(126,126,126,0.40)_3.01%,rgba(126,126,126,0.00)_107.67%)] backdrop-blur-[4.5px] border-2 border-solid border-[#ffffff1f] rounded-[8px] p-[10px_16px] text-[22px] font-zendots font-semibold leading-normal tracking-wide'>{`${categoryName}'s Pool`}</div>
                                    <button
                                        disabled={isClaiming}
                                        className={`flex justify-center items-center w-full md:w-[196px] h-[48px] text-[16px] font-semibold leading-normal border-2 border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] ${isClaiming ? 'opacity-30' : 'hover:bg-[#99b7ff]'}`}
                                        onClick={handleClaim}
                                    >
                                        <span>Claim Rewards</span>
                                        {
                                            isClaiming && (
                                                <TailSpin
                                                    height="18"
                                                    width="18"
                                                    color="#ffffff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            )
                                        }
                                    </button>
                                </div>

                                <div className='w-full bg-[linear-gradient(132deg,rgba(126,126,126,0.40)_3.01%,rgba(126,126,126,0.00)_107.67%)] backdrop-blur-[4.5px] border-2 border-solid border-[#ffffff1f] rounded-[8px] p-[12px_16px] md:p-[20px_24px] mt-[20px]'>
                                    <div className='grid grid-cols-3 md:flex md:justify-between items-center gap-[20px] md:gap-[10px] w-full font-semibold'>
                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit'>
                                                <span className='text-center'>NFTs Staked</span>
                                                <span className='text-[#99b7ff]'>{Number(stakingPool.nftRemaining.toString())}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit'>
                                                <span className='text-center'>Pool StartTime</span>
                                                <span className='text-[#99b7ff]'>{`${new Date(Number(stakingPool.startTime.toString()) * 1000).toLocaleString()}`}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit'>
                                                <span className='text-center'>Pool EndTime</span>
                                                <span className='text-[#99b7ff]'>{`${new Date(Number(stakingPool.endTime.toString()) * 1000).toLocaleString()}`}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit'>
                                                <span className='text-center'>Reward Rate</span>
                                                <span className='text-[#99b7ff]'>{(Number(stakingPool.nftStaked.toString()) == 0 ? 0 : Number(ethers.utils.formatUnits(stakingPool.tokenAllocated.toString(), csdogeTokenDecimals)) / Number(stakingPool.nftStaked.toString()) / (Number(stakingPool.endTime.toString()) - Number(stakingPool.startTime.toString()))).toFixed(5)}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit'>
                                                <span className='text-center'>Tokens Allocated</span>
                                                <span className='text-[#99b7ff]'>{Number(ethers.utils.formatUnits(stakingPool.tokenAllocated.toString(), csdogeTokenDecimals)).toLocaleString()}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit'>
                                                <span className='text-center'>Tokens Remaining</span>
                                                <span className='text-[#99b7ff]'>{Number(ethers.utils.formatUnits(stakingPool.tokenRemaining.toString(), csdogeTokenDecimals)).toLocaleString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex items-center gap-[10px] mt-[20px]'>
                                    <button
                                        className={`flex justify-center items-center w-[120px] h-[40px] text-[16px] font-semibold leading-normal rounded-full hover:bg-[#99b7ff] ${filterType == FilterTypeEnum.MyCsdoge ? 'bg-[#99b7ff]' : ''}`}
                                        onClick={() => setFilterType(FilterTypeEnum.MyCsdoge)}
                                    >
                                        My Csdoge
                                    </button>
                                    <button
                                        className={`flex justify-center items-center w-[120px] h-[40px] text-[16px] font-semibold leading-normal rounded-full hover:bg-[#99b7ff] ${filterType == FilterTypeEnum.MyStaking ? 'bg-[#99b7ff]' : ''}`}
                                        onClick={() => setFilterType(FilterTypeEnum.MyStaking)}
                                    >
                                        My Staking
                                    </button>
                                </div>

                                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[20px] w-full mt-[20px]'>
                                    {
                                        (filterType == FilterTypeEnum.MyCsdoge && userNfts.length > 0) || (filterType == FilterTypeEnum.MyStaking && userStakeNfts.length > 0) ? (
                                            (filterType == FilterTypeEnum.MyCsdoge ? userNfts : userStakeNfts).map((item, index) => {
                                                const img = new Image();
                                                img.src = item.imgUri;

                                                let isHeightBiggerThanWidth = true;
                                                if (img.height < img.width) {
                                                    isHeightBiggerThanWidth = false;
                                                }

                                                return (
                                                    <div key={index} className={`flex flex-col items-center w-[320px] ${filterType == FilterTypeEnum.MyCsdoge ? 'h-[540px]' : 'h-[560px]'} bg-[#141324] rounded-[8px] mx-auto hover:scale-[1.01] transition-all duration-150`}>
                                                        {/* NFT image */}
                                                        <div className='flex justify-center items-center w-full h-[400px]'>
                                                            <Link to={`/details?tokenId=${item.tokenId.toString()}`} className='flex justify-center items-center w-full h-full'>
                                                                <img src={item.imgUri} className={`${isHeightBiggerThanWidth ? 'h-full max-w-full' : 'w-full'}`} />
                                                            </Link>
                                                        </div>

                                                        <div className='grow flex flex-col items-center w-full py-[10px] px-[20px]'>
                                                            {/* NFT name */}
                                                            <div className='w-full text-[20px] text-center font-semibold leading-[100%]'>{item.name}</div>

                                                            <div className='grow w-full text-[16px] mt-[15px]'>
                                                                {/* China logo */}
                                                                <div className='flex justify-between items-center w-full'>
                                                                    <div className='flex justify-center items-center gap-[6px]'>
                                                                        <span className='text-[#B5B5BA]'>Chain:</span>
                                                                        <img src={bscLogo} className='w-[20px]' alt='bsc-logo' />
                                                                    </div>

                                                                    <div className='flex justify-center items-center gap-[6px]'>
                                                                        <span className='text-[#B5B5BA]'>TokenId:</span>
                                                                        <span>{`#${item.tokenId.toString()}`}</span>
                                                                    </div>
                                                                </div>

                                                                {/* Amount */}
                                                                <div className='flex justify-between items-center gap-[6px]'>
                                                                    <span className='text-[#B5B5BA]'>Amount:</span>
                                                                    <span>
                                                                        {
                                                                            filterType == FilterTypeEnum.MyCsdoge ? (
                                                                                item.amount.toString()
                                                                            ) : (
                                                                                item?.stakedAmount?.toString()
                                                                            )

                                                                        }
                                                                    </span>
                                                                </div>

                                                                {/* StakedAt */}
                                                                {
                                                                    filterType == FilterTypeEnum.MyStaking && (
                                                                        <div className='flex justify-between items-center gap-[6px]'>
                                                                            <span className='text-[#B5B5BA]'>StakedAt:</span>
                                                                            <span>{`${new Date(Number(item?.stakedAt?.toString()) * 1000).toLocaleString()}`}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>

                                                            {/* Stake NFT */}
                                                            <div className='flex justify-between items-center gap-[10px] w-full'>
                                                                {
                                                                    filterType == FilterTypeEnum.MyCsdoge ? (
                                                                        <>
                                                                            <input
                                                                                type='number'
                                                                                placeholder='Input number'
                                                                                min={0}
                                                                                step={1}
                                                                                value={amounts[index] ? amounts[index] : ''}
                                                                                className='grow h-[26px] bg-[#141324] border-b border-b-[#99b7ff] outline-none'
                                                                                onChange={e => setAmounts(current => {
                                                                                    current[index] = e.target.value ? Number(e.target.value) : undefined;
                                                                                    return [...current];
                                                                                })}
                                                                            />
                                                                            <button
                                                                                disabled={isStaking}
                                                                                className={`flex justify-center items-center gap-[5px] min-w-[55px] h-[26px] border border-solid border-[#99b7ff] rounded-[5px] bg-[#2e2e49] ${isStaking ? 'opacity-30' : 'hover:bg-[#99b7ff]'}`}
                                                                                onClick={() => handleStake(index, Number(item.tokenId.toString()))}
                                                                            >
                                                                                <span>Stake</span>
                                                                                {
                                                                                    isStaking && index == selectedIndex && (
                                                                                        <TailSpin
                                                                                            height="18"
                                                                                            width="18"
                                                                                            color="#ffffff"
                                                                                            ariaLabel="tail-spin-loading"
                                                                                            radius="1"
                                                                                            wrapperStyle={{}}
                                                                                            wrapperClass=""
                                                                                            visible={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <button
                                                                            disabled={isUnstaking}
                                                                            className={`flex justify-center items-center gap-[10px] w-full h-[30px] border border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] ${isUnstaking ? 'opacity-30' : 'hover:bg-[#99b7ff]'}`}
                                                                            onClick={() => handleUnstake(index, Number(item.tokenId.toString()))}
                                                                        >
                                                                            <span>Unstake</span>
                                                                            {
                                                                                isUnstaking && index == selectedIndex && (
                                                                                    <TailSpin
                                                                                        height="18"
                                                                                        width="18"
                                                                                        color="#ffffff"
                                                                                        ariaLabel="tail-spin-loading"
                                                                                        radius="1"
                                                                                        wrapperStyle={{}}
                                                                                        wrapperClass=""
                                                                                        visible={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </button>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className='col-span-1 md:col-span-2 lg:col-span-3 2xl:col-span-4 text-[#808080] text-center mt-[20px]'>No items</div>
                                        )
                                    }
                                </div>
                            </>
                        ) : (
                            <div className='text-[#808080] text-center mt-[20px]'>No items</div>
                        )
                    ) : (
                        <div className='flex justify-center items-center gap-[20px] text-[#808080] text-center mt-[20px]'>
                            <span className='text-[26px]'>Loading...</span>

                            <TailSpin
                                height="32"
                                width="32"
                                color="#808080"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    )
                }

            </div >
        </div >
    );
};

export default StakingPoolDetail;